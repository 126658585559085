import Layout from "../containers/Layout";

import PageHelmet from "../components/PageHelmet";
import * as styles from "./404.module.scss";
import { bodyTextStyle } from "../components/typography.module.scss";
import { cn } from "../lib/helpers";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <PageHelmet title="404: Not found" />
    <div className={cn(bodyTextStyle, styles.root)}>
      <div>
        <h1>404</h1>
        <p>Whoops! This page doesn't exist.</p>
        <Link to="/">Go home</Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
